import React, {useEffect, useRef, useState} from "react";
import {WebSocketClient} from "../Comms/WebSocketClient";
import {IMessage} from "@stomp/stompjs";
import { InitialisationObject} from "../../App";
import { setMessages } from '../../store/Reducers/Messages';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setConnection } from "../../store/Reducers/Connection";
import { Movement, setMovement } from "../../store/Reducers/Movements";
import { North, South, East, West, NorthEast, NorthWest, SouthEast, SouthWest, Close } from "@mui/icons-material";
import { thisPlayer } from "../../store/Reducers/Players";

interface Props {
    webSocketClient:WebSocketClient;
    onInitialise: Function;
    playerId: string;
    initialisationData:Movement[],
    setPositionState: Function,
    updateInitialisationData: Function
}

interface SendMovement {
    position: {
        x :number,
        y :number,
        z :number
    },
    velocity: {
        x :number,
        y :number,
        z :number
    },
    rotation: number,
    playerId: string
}


const PositionTracking : React.FC<Props> = ({webSocketClient, onInitialise, playerId, initialisationData, setPositionState, updateInitialisationData}) => { 

    const [sendCoords, setSendCoords] = useState<SendMovement>({position: { x:0,y:0,z:0}, velocity: { x:0,y:0,z:0},rotation: 0 ,playerId:playerId})
  
    const {websocket} = useAppSelector(state => state.connection)
    const {pause} = useAppSelector(state => state.messages)
    const [repeat, setRepeat] = useState<number>(0)
    const pauseRef = useRef(pause)
    let dispatch = useAppDispatch()
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        console.log("Position tracking adding Subscription")
        websocket.addSubscription(setInitialisationData, onMessage, disconnectUser)
    }, [websocket]);

    useEffect(() => {
        pauseRef.current = pause
    }, [pause])

    const handleClick = (x: number, y: number, repeat: number) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (repeat === 0) {
            publishToServer(x, y);
            intervalRef.current = null;
        } else {
            intervalRef.current = setInterval(() => {
                publishToServer(x, y);
            }, 1000 / repeat);
        }
    };

    const publishToServer = (x:number, y: number) => {
        const width = window.innerWidth / 3;
        const height = window.innerHeight / 3;
        
        const playerX = Math.min(Math.max(sendCoords.position.x * 10 + width / 2, 10), width - 10)
        const playerY = Math.min(Math.max(sendCoords.position.z * 10 + height / 2, 10), height - 10)


        if ((playerX > 10 && playerX < width - 10) || (playerX === 10 && x > 0) || (playerX === width - 10 && x < 0)) {
            sendCoords.position.x += x;
        }
        sendCoords.position.y += 0
        if ((playerY > 10 && playerY < height - 10) || (playerY === 10 && y > 0) || (playerY === height - 10 && y < 0)) {
            sendCoords.position.z += y;
        }

        sendCoords.velocity.x += x
        sendCoords.velocity.y += 0
        sendCoords.velocity.z = y

        sendCoords.rotation = 100
        sendCoords.playerId = playerId
        webSocketClient?.publishObjToMovementTopic( sendCoords )
    }

    const handleDisconnectClick = () =>{
        webSocketClient?.disconnect()
        dispatch(setMessages("User: " + thisPlayer + " disconnected at: " + new Date().toISOString()))
        dispatch(setConnection({playerId: thisPlayer, timestamp:new Date().toISOString()}))
    }

    const onMessage = (message:IMessage) => {
        try {
            onChangePosition(JSON.parse(message.body))
            dispatch(setMessages(message.body))
        } catch(error) {
            dispatch(setMessages((error as Error).message))
        }
    }

    const setInitialisationData = (message:IMessage) => {
        console.log(message.body)
        const initialisationObject:InitialisationObject = JSON.parse(message.body)
        onInitialise(initialisationObject.playerMovements, initialisationObject.avatarDefinitions)
        handleClick(0,0,0)
    }


    const disconnectUser = (message:IMessage) => {
        let json = JSON.parse(message.body)
        console.log(json)
        for(let i = 0; i < json.length; i++){
            dispatch(setMessages("User: " + json[i].playerId + " disconnected at: " + json[i].timestamp))
            dispatch(setConnection(json[i]))
        }
        
    } 
    
    const onChangePosition = (movements: Movement[]) => {
        if(!pauseRef.current){
            dispatch(setMovement(movements));
        }   
    };
    

    return (
            <div className="card dropDownCard">
                    <div className={"buttonHolder"}>
                        <button className="cntrlBtn" onClick={() => handleClick(-1,-1,repeat)}><NorthWest/></button>
                        <button className="cntrlBtn" onClick={() => handleClick(0,-1,repeat)}><North /></button>
                        <button className="cntrlBtn" onClick={() => handleClick(1,-1,repeat)}><NorthEast/></button> <br />
                        <button className="cntrlBtn" onClick={() => handleClick(-1,0,repeat)}><West /></button>
                        <button className="cntrlBtn" onClick={() => handleClick(0,0,0)}><Close/></button>
                        <button className="cntrlBtn" onClick={() => handleClick(1,0,repeat)}><East/></button>
                        <br />
                        <button className="cntrlBtn" onClick={() => handleClick(-1, 1,repeat)}><SouthWest/></button>
                        <button className="cntrlBtn" onClick={() => handleClick(0,1,repeat)}><South/></button>
                        <button className="cntrlBtn" onClick={() => handleClick(1,1,repeat)}><SouthEast/></button><br />
                        <button className="customButton" onClick={() => handleDisconnectClick()}>Disconnect</button>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{marginRight: '10px'}}>Repeat: </p>
                        <input type="number" value={repeat} onChange={(e) => setRepeat(parseInt(e.target.value))} style={{width: '60%'}}></input>
                        </div>
                    </div>
    );

}

export default PositionTracking;