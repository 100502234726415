import React, { useEffect, useRef } from "react";
import { WebSocketClient } from "../Comms/WebSocketClient";
import { IMessage } from "@stomp/stompjs";
import { useDispatch } from "react-redux";
import { setMessageHistory } from "../../store/Reducers/Messages";
import { useAppSelector } from "../../store/hooks";
import ChatInput from "./ChatInput";
import ChatBubble from "./chatWindowComponents/ChatBubble";

type Props = {
    websocket: WebSocketClient
}

export const ChatWindow: React.FC<Props> = ({websocket}) => {

    const { messageHistory } = useAppSelector((state) => state.messages)
    const messageRef = useRef(messageHistory)
    const {userMessages,playerLoggedIn} = useAppSelector((state) => state.players)
    const dispatch = useDispatch()

    useEffect(() => {
        websocket.addChatSubscription(updateMessage)
    }, [websocket])

    const updateMessage= (message:IMessage) => {
        console.log("Received chat message: %s ", message.body)  
        formatMessages(message.body)
    }

    useEffect(() => {
        messageRef.current = messageHistory
    },[messageHistory])

    
    // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
    function formatMessages(message: string){
        let data = JSON.parse(message)
        let currentMessages: Array<any> = [...messageRef.current]

        if(currentMessages.length > 500){
            
            currentMessages.shift()
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.playerId ? data.playerId : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))

        } else {
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.playerId ? data.playerId : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))

        }
    }

    function formatUnityMessages(message: string){
        let currentMessages: Array<any> = [...messageRef.current]
        currentMessages.push({from: "unity", msg: message, guid: "12345", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
        
        dispatch(setMessageHistory(currentMessages))
        console.log(messageHistory)
    }


    // display the latest message at the top
    function reverseHistory(){
        let currentMessages = [...messageHistory]
        return currentMessages.reverse()
    }

    // check the stored user sent messages so that styling can be applied to the chat window
    function checkUserMessages(msgGuid: string) {
        for (const usrMsg of userMessages) {
            if (msgGuid=== playerLoggedIn.playerId) {
                return true;
            }
        }
        return false;
    }

    return(
        <div className="chat">
            <h2 className="chatText">Live Chat</h2>
            <div className="chat-container">
                <ul>
                    {
                        reverseHistory().map((msg, index) => (
                            
                            checkUserMessages(msg.guid) ? 
                            <ChatBubble key={`chat${index}`} index={index} className="user-chat-bubble" msg={msg} timeClassName="user-bubble-timestamp"/>
                            :
                            <ChatBubble key={`chat${index}`} index={index} className={msg.from === "unity" ? "unity-chat-bubble" :"chat-bubble"} msg={msg} timeClassName="bubble-timestamp"/>
                        ))
                    }
                </ul>
            </div>
            <ChatInput websocket={websocket}/>
        </div>
    )
}