import React from "react";


type msg = {
    msg: string,
    from: string,
    timestamp: string
}
type chatBubbleProps = {
    index: number,
    className: string,
    msg: msg,
    timeClassName: string
}

const ChatBubble: React.FC<chatBubbleProps> = ({index,className,msg,timeClassName}) => {
    
    return(
        <div>
            <li key={index} className={className}>{msg.msg}</li>
            <li className={timeClassName}>{msg.from} {msg.timestamp}</li>
        </div>
    )
}

export default ChatBubble;